export const tabsMap = {
  all: "Все",
  found: "Найдено",
  not_found: "Не найдено",
  not_found_sex: "Нет пола",
  not_found_subject: "Нет предмета",
  not_found_season: "Нет сезона",
  not_found_today: "Вылетели",
};

export const tabKeys = [
  "all",
  "found",
  "not_found_today",
  "not_found",
  "not_found_subject",
  "not_found_sex",
  "not_found_season",
];
