import _ from "lodash";
import "./App.css";

import {
  Box,
  createTheme,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { lazy, useEffect, useState } from "react";
import { ReactComponent as Checkbox } from "./Assets/Icons/Checkbox.svg";
import { ReactComponent as CheckboxSelected } from "./Assets/Icons/CheckboxSelected.svg";
import { tabKeys, tabsMap } from "./Config/constants";
import { getCookie, setCookie } from "./Helpers/cookie.helper";
import {
  getAlsoFoundData,
  getCategoriesData,
  getTablesData,
} from "./Helpers/dataBuilder";
import { options } from "./options";
import Api from "./Services/api.service";

import "./Components/Table.css";

const Table = lazy(() => import("./Components/Table"));

const TabsLoadingSkeleton = () => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          borderRadius: "5px",
          mt: "7px",
          height: "120px",
          width: "100%",
        }}
      />
    </Box>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});

const params = Object.fromEntries(
  new URLSearchParams(window.location.search.slice(1))
);

const BOUNDARY_STRING = "1234567890!@#$%^&*(qwertyuiop[]";

const getTabAndSectionFromCookie = () => {
  const { offer, pid } = params;
  const cookieKey = pid + BOUNDARY_STRING + offer;
  const [tabKey, sectionKey] =
    getCookie(cookieKey)?.split(BOUNDARY_STRING) || [];
  return { tabKey, sectionKey };
};

const setTabAndSectionToCookie = (tabKey, sectionKey) => {
  const { offer, pid } = params;
  const cookieKey = pid + BOUNDARY_STRING + offer;
  const oldValue = getTabAndSectionFromCookie();
  const cookieValue =
    (tabKey ? tabKey : oldValue.tabKey) +
    BOUNDARY_STRING +
    (sectionKey ? sectionKey : oldValue.sectionKey);
  setCookie(cookieKey, cookieValue);
};

const App = () => {
  try {
    document.querySelector(
      'div[style="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;"'
    ).style.display = "none";
  } catch {}
  const [tablesData, setTablesData] = useState({});
  options.setData = setTablesData;
  const [categoriesData, setCategoriesData] = useState({});
  const [alsoFoundData, setAlsoFoundData] = useState({});

  const [tableKey, setTableKey] = useState(null);
  const [sectionKey, setSectionKey] = useState(null);
  const rows =
    tableKey === "cats"
      ? categoriesData.rows
      : tableKey === "also"
      ? alsoFoundData.rows
      : tablesData.tables?.[tableKey]?.[sectionKey];

  const columns =
    tableKey === "cats"
      ? categoriesData.columns
      : tableKey === "also"
      ? alsoFoundData.columns
      : tablesData.columns;

  const changeTable = (inputTableKey, inputSectionKey) => {
    const { tabKey: oldTabKey, sectionKey: oldSectionKey } =
      getTabAndSectionFromCookie();
    const newTableKey = inputTableKey || tableKey || oldTabKey;
    const newSectionKey = inputSectionKey || oldSectionKey || sectionKey;

    const newSectionKeys = tabKeys.filter(
      (tabKey) => tablesData.tables?.[newTableKey]?.[tabKey]?.length
    );
    const newValidSectionKey =
      newSectionKeys.includes(newSectionKey) || newSectionKeys.length === 0
        ? newSectionKey
        : newSectionKeys[0];

    setTabAndSectionToCookie(newTableKey, newValidSectionKey);
    setTableKey(newTableKey);
    setSectionKey(newValidSectionKey);
    window.top?.postMessage?.("sel=" + newTableKey, "*");
  };

  const handleTabsChange = (event, value) => {
    changeTable(value, undefined);
  };

  const handleSectionChange = (event, value) => {
    changeTable(undefined, value);
  };

  const fetchData = async () => {
    setTableKey(null);
    setTablesData({});
    setCategoriesData({});
    setAlsoFoundData({});
    const res = await Api.getData();
    const data = getTablesData(res);
    const catData = getCategoriesData(res);
    const alsoFound = getAlsoFoundData(res);
    data.tabs["cats"] = "Категории";
    data.tabs["also"] = "Также найдено";

    const { tabKey, sectionKey } = getTabAndSectionFromCookie();
    const firstTableKey = tabKey || Object.keys(data.tables)[0];
    const firstSectionKey =
      sectionKey || Object.keys(data.tables[firstTableKey] || {})[0];
    setTablesData(data);
    changeTable(firstTableKey, firstSectionKey);
    setCategoriesData(catData);
    setAlsoFoundData(alsoFound);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabElements = [];
  const tabs = {};

  for (const key in tablesData.tabs) {
    const foundCount = tablesData.tables?.[key]?.["found"]?.length || 0;
    const totalCount = tablesData.tables?.[key]?.["all"]?.length || 0;
    tabs[key] = {
      name: tablesData.tabs[key],
      totalCount,
      foundCount,
    };
    const headerName = tablesData.tabs[key];
    // tablesData.tabs[key][0].toUpperCase() + tablesData.tabs[key].slice(1).toLowerCase();
    const label =
      key === "cats"
        ? `${(categoriesData.rows?.length || 0).toLocaleString("ru")}`
        : key === "also"
        ? `${(alsoFoundData.rows?.length || 0).toLocaleString("ru")}`
        : `${foundCount.toLocaleString("ru")} / ${totalCount.toLocaleString(
            "ru"
          )}`;
    tabElements.push(
      <Tab
        style={{
          textTransform: "none",
        }}
        sx={{
          maxWidth: "1000px",
        }}
        key={key}
        value={key}
        label={
          <Box sx={{ display: "flex" }}>
            {headerName}
            {"\u00a0\u00a0"}
            <Box
              sx={{
                color: "#666666",
                opacity: 0.5,
              }}
            >
              {label}
            </Box>
          </Box>
        }
      />
    );
  }

  const sectionElements = [];
  const sectionKeys = [];
  for (const key of tabKeys) {
    const sectionQuriesCount =
      tablesData.tables?.[tableKey]?.[key]?.length || 0;
    if (sectionQuriesCount > 0) {
      sectionKeys.push(key);
      sectionElements.push(
        <FormControlLabel
          sx={{ mr: "40px" }}
          key={key}
          value={key}
          control={
            <Radio
              value={key}
              icon={<SvgIcon component={Checkbox} />}
              checkedIcon={<SvgIcon component={CheckboxSelected} />}
            />
          }
          label={
            <Box
              sx={{
                display: "flex",
              }}
            >
              {tabsMap[key]}
              {"\u00a0\u00a0"}
              <Typography sx={{ color: "#333333", opacity: "0.5" }}>
                {sectionQuriesCount}
              </Typography>
            </Box>
          }
        />
      );
    }
  }

  const regenerateAllQueriesTable = (newTablesData) => {
    const allQuries = {};
    const allTable = newTablesData.tables.all;
    for (const section in allTable) {
      allTable[section] = [];
      allQuries[section] = new Set();
    }
    for (const tableKey in newTablesData.tables) {
      if (tableKey === "all") continue;
      const currentTable = newTablesData.tables[tableKey];
      for (const currentTableSection in currentTable) {
        for (const row of currentTable[currentTableSection]) {
          const rowKey = row[0];
          if (!allQuries[currentTableSection].has(rowKey)) {
            allQuries[currentTableSection].add(rowKey);
            allTable[currentTableSection].push(row);
          }
        }
      }
    }
  };

  const removeRowsFromTables = async ({
    queries,
    tables,
    data,
    rerender = true,
  }) => {
    const rowKeysSet = new Set(queries);
    const tableKeysSet = new Set(tables);

    const newTablesData = data || _.cloneDeep(tablesData);
    for (const tableKey in newTablesData.tables) {
      if (tableKeysSet.has(tableKey)) {
        const currentTable = newTablesData.tables[tableKey];
        for (const section in currentTable) {
          currentTable[section] = [];
          for (const row of tablesData.tables[tableKey][section]) {
            const rowKey = row[0];
            if (!rowKeysSet.has(rowKey)) {
              currentTable[section].push(row);
            }
          }
        }
      }
    }
    regenerateAllQueriesTable(newTablesData);
    if (rerender) setTablesData(newTablesData);
    return newTablesData;
  };

  const copyRowsFromTableToTables = async ({
    rows,
    queries,
    selectedSection,
    tables,
    data,
    rerender = true,
  }) => {
    const newTablesData = data || _.cloneDeep(tablesData);

    for (const table of tables) {
      const currentTable = newTablesData.tables[table];
      for (let i = 0; i < queries.length; ++i) {
        const query = queries[i];
        const row = rows[i];
        let found = false;
        if (!currentTable[selectedSection]) currentTable[selectedSection] = [];
        for (const currentRow of currentTable[selectedSection]) {
          if (currentRow[0] === query) {
            found = true;
            break;
          }
        }
        if (!found) currentTable[selectedSection].push(row);
        found = false;
        for (const currentRow of currentTable["all"]) {
          if (currentRow[0] === query) {
            found = true;
            break;
          }
        }
        if (!found) currentTable["all"].push(row);
      }
    }

    regenerateAllQueriesTable(newTablesData);
    if (rerender) setTablesData(newTablesData);
    return newTablesData;
  };

  const moveRowsFromTableToTables = async ({
    rows,
    queries,
    selectedSection,
    selectedTable,
    tables,
  }) => {
    const newTablesData = await copyRowsFromTableToTables({
      rows,
      queries,
      selectedSection,
      tables,
      rerender: false,
    });
    await removeRowsFromTables({
      queries,
      tables: [selectedTable],
      data: newTablesData,
    });
  };

  return (
    <Box
      className="font-montserrat"
      sx={{
        backgroundColor: '#ffffff',
        boxSizing: "border-box",
        height: document.documentElement.clientHeight - 200,
        width: "99%",
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          boxSizing: "border-box",
          width: "inherit",
          padding: "0 10px 10px 0",
          marginLeft: "10px",
          marginRight: "10px",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .Mui-selected": {
            borderBottom: "2px solid #72A0EC",
            color: "#333333 !important",
          },
          "& .Mui-checked ~ span": {
            color: "#666666",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          {!tableKey ? (
            <TabsLoadingSkeleton />
          ) : (
            <>
              <Tabs
                variant="standard"
                onChange={handleTabsChange}
                value={tableKey}
                sx={{
                  marginBottom: "10px",
                  "& .MuiTab-root": {},
                  "& .MuiTabs-flexContainer": {
                    overflow: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                  },
                }}
              >
                {tabElements}
              </Tabs>
              {!["cats", "also"].includes(tableKey) && (
                <>
                  <FormControl sx={{ margin: "10px" }}>
                    <RadioGroup
                      onChange={handleSectionChange}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      value={sectionKey}
                    >
                      {sectionElements}
                    </RadioGroup>
                  </FormControl>
                </>
              )}
            </>
          )}
        </ThemeProvider>
      </Box>
      <Table
        removeRowsFromTables={removeRowsFromTables}
        moveRowsFromTableToTables={moveRowsFromTableToTables}
        copyRowsFromTableToTables={copyRowsFromTableToTables}
        rows={rows || []}
        columns={columns || []}
        loading={!tableKey}
        tabs={tabs}
        fetchData={fetchData}
        selectedTable={tableKey}
        selectedSection={sectionKey}
      />
    </Box>
  );
};

export default App;
