import axios from "axios";
// import data from './data.json';

const params = Object.fromEntries(
  new URLSearchParams(window.location.search.slice(1))
);

const postQueries = async (queries, from, tables, act) => {
  const formData = new FormData();
  for (const table of tables) {
    formData.append(`cmd[${table}]`, table);
  }

  formData.append("qs", queries.join("\r\n"));
  formData.append("from_list", from);
  formData.append("act", act);
  formData.append("copy_move_del", "1");

  return (await axios({
    method: "post",
    url: `https://marpla.ru/wb/index.php?page=report&pid=${params.pid}&offer=${params.offer}&userid=${params.userid}&userkey=${params.userkey}&short=1`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }))?.data;
};

const Api = {
  params: {},
  getData: async () => {
    const res = await fetch(
      `https://marpla.ru/wb/index.php?page=report&pid=${params.pid}&offer=${params.offer}&data&userid=${params.userid}&userkey=${params.userkey}`
    );
    return await res.json();
    // return data;
  },

  getPosition: async (query) => {
    const res = await fetch(
      `https://marpla.ru/wb/parse_all.php?pid=${params.pid}&art=${params.offer}&one_q=${query}&userid=${params.userid}&userkey=${params.userkey}`
    );
    const resText = await res.text();
    const newPosition = Number(resText.split("===POS===")[1].replace(/[^\d]+/g, ''));
    return newPosition === 0 ? '-' : newPosition;
  },

  removeFromTables: async (queries, from, tables) => {
    return await postQueries(queries, from, tables, "del");
  },

  duplicateToTables: async (queries, from, tables) => {
    return await postQueries(queries, from, tables, "copy");
  },

  moveToTables: async (queries, from, tables) => {
    return await postQueries(queries, from, tables, "move");
  },
};

export default Api;
