import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";


const oldConsoleError = console.error;
console.error = (...args) => {
  for(const arg of args) {
    if (arg?.includes?.('MUI: License key not found.')) return;
  }
  oldConsoleError(...args);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
