import { Link, SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import {
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import Api from "../Services/api.service";

import { ReactComponent as ParseIcon } from "../Assets/Icons/Parse.svg";
import { ReactComponent as ParseUpdatedIcon } from "../Assets/Icons/ParseUpdated.svg";

import { isDateHeader } from "../utils/dataUtils";
import "../Components/Table.css";

const getFormattedValue = (value) => {
  const valuesMap = {
    99991: "1к+",
    99992: "2к+",
    99993: "3к+",
    99995: "5к+",
    99998: "10к+",
    99999: "✅",
    [Infinity]: "-",
  };

  return valuesMap[value] || value;
};

const getNumericalValueForSorting = (formattedValue) => {
  const valuesMap = {
    "1к+": 99991,
    "2к+": 99992,
    "3к+": 99993,
    "5к+": 99995,
    "10к+": 99998,
    "✅": 99999,
    "-": Infinity,
  };

  return valuesMap[formattedValue] || formattedValue;
};

const RenderedCell = ({ params }) => {
  const [parsingQuery, setParsingQuery] = useState(false);
  const [wasUpdatedManually, setWasUpdatedManually] = useState(false);
  return (
    <Box
      className="query-header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        borderRight: "1px solid #F2F2F2",
      }}
    >
      <Link
        onMouseDown={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
        href={
          "https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=" +
          params.value
        }
      >
        {params.value}
      </Link>
      {parsingQuery ? (
        <CircularProgress
          size={"14px"}
          style={{ color: "#999999" }}
          sx={{ mr: "10px" }}
        />
      ) : (
        <SvgIcon
          component={wasUpdatedManually ? ParseUpdatedIcon : ParseIcon}
          className="parse-icon"
          // className={wasUpdatedManually ? "parse-updated-icon" : "parse-icon"}
          sx={{
            cursor: "pointer",
            mr: "5px",
            WebkitTransform: "scaleX(-1)",
            transform: "scaleX(-1)",
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={async (e) => {
            e.stopPropagation();
            if (parsingQuery) return;
            params.api.updateRows([
              {
                id: params.id,
                4: (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      size={"14px"}
                      color="success"
                      sx={{ mr: "10px" }}
                    />
                  </Box>
                ),
                wasUpdatedManually: false,
              },
            ]);
            setParsingQuery(true);
            let newPosition = params.row[4];
            try {
              newPosition = await Api.getPosition(params.value);
            } catch {
              console.log("Error while fetching new position");
            }
            setParsingQuery(false);
            setWasUpdatedManually(true);
            params.api.updateRows([
              {
                id: params.id,
                4: getFormattedValue(newPosition),
                wasUpdatedManually: true,
              },
            ]);
          }}
        />
      )}
    </Box>
  );
};
export const getAlsoFoundData = (data) => {
  const alsoFoundColumns = ["Запрос", "Карточек", "Частота OZ", "Частота WB"];

  const rows = [];
  for (const key in data.also_found_data) {
    const row = { id: key };
    for (let i = 0; i < alsoFoundColumns.length; ++i) {
      row[i] = data.also_found_data[key][i];
    }
    rows.push(row);
  }

  const columns = [
    {
      field: "0",
      headerName: alsoFoundColumns[0],
      width: 400,
      renderHeader: (params) => {
        return (
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{
              fontFamily: "Montserrat",
              display: "flex",
              justifyContent: "space-between",
              width: "330px",
              alignItems: "center",
            }}
          >
            <Box>{params.colDef.headerName}</Box>
            <Box>
              <GridToolbarQuickFilter
                sx={{
                  width: "150px",
                  height: "50%",
                  fontFamily: "Montserrat",
                }}
                type="text"
              />
              <GridToolbarFilterButton />
            </Box>
          </Box>
        );
      },
      renderCell: (params) => (
        <Link
          onMouseDown={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
          href={
            "https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=" +
            params.value
          }
        >
          {params.value}
        </Link>
      ),
    },
  ];

  for (let i = 1; i < alsoFoundColumns.length; ++i) {
    columns.push({
      field: i.toString(),
      headerName: alsoFoundColumns[i],
      width: 110,
      type: "number",
      getApplyQuickFilterFn: undefined,
      renderCell: (params) => {
        const numericValue = Number(params.value);
        return Number.isNaN(numericValue)
          ? params.value
          : numericValue.toLocaleString("ru");
      },
      sortComparator: (v1, v2) => {
        const numV1 = Number(v1) || 0;
        const numV2 = Number(v2) || 0;
        return Number(numV1) - Number(numV2);
      },
    });
  }

  return { rows, columns };
};

export const getCategoriesData = (data) => {
  const emptyTabIds = new Set();
  const categoryColumns = [...data.categories_cols];
  categoryColumns.forEach((col, index) => {
    if (!col) emptyTabIds.add(index.toString());
  });
  categoryColumns.push("Топ", "Дельта", "Дельта ТОПа");

  const dateColumnIndices = categoryColumns
    .map((_, index) => index)
    .filter((index) => isDateHeader(categoryColumns[index]));

  const rows = data.categories_data.map((r) => {
    const row = { id: r[0] };
    let dateValues = [];
    for (let i = 0; i < categoryColumns.length - 3; ++i) {
      const rawValue = r[i];
      const currentCellValue = dateColumnIndices.includes(i)
        ? getFormattedValue(rawValue)
        : rawValue;
      row[i] = currentCellValue;
      if (dateColumnIndices.includes(i)) {
        dateValues.push(currentCellValue);
      }
    }
    const filteredDateValue = dateValues.filter(
      (val) => !Number.isNaN(Number(val))
    );
    const topDateValue =
      filteredDateValue.length > 0 ? Math.min(...filteredDateValue) : "−";
    row[categoryColumns.length - 3] = topDateValue;
    row[categoryColumns.length - 2] = !Number.isNaN(
      dateValues[1] - dateValues[0]
    )
      ? dateValues[1] - dateValues[0]
      : null;
    row[categoryColumns.length - 1] = !Number.isNaN(
      topDateValue - dateValues[0]
    )
      ? topDateValue - dateValues[0]
      : null;
    row.title = r.title;
    return row;
  });

  const columns = [
    {
      field: "0",
      headerName: categoryColumns[0],
      headerAlign: "left",
      headerClassName: "pinned-header",
      width: 400,
      hideable: true,
      renderHeader: (params) => {
        return (
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "330px",
              alignItems: "center",
            }}
          >
            <Box>{params.colDef.headerName}</Box>
            <Box>
              <GridToolbarQuickFilter
                sx={{
                  width: "150px",
                  height: "50%",
                }}
                type="text"
              />
              <GridToolbarFilterButton />
            </Box>
          </Box>
        );
      },
      renderCell: (params) => (
        <>
          <FolderOpenIcon sx={{ m: "5px" }} />
          <Link
            onMouseDown={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
            href={"https://www.wildberries.ru" + params.value}
          >
            {params.row.title}
          </Link>
        </>
      ),
    },
  ];

  for (let i = 1; i < categoryColumns.length; ++i) {
    columns.push({
      field: i.toString(),
      headerName: categoryColumns[i],
      headerAlign: "left",
      headerClassName: "pinned-header",
      width: 110,
      type: "number",
      getApplyQuickFilterFn: undefined,
      renderCell: (params) => {
        const numericValue = Number(params.value);
        return (
          <div>
            <div>
              {Number.isNaN(numericValue)
                ? params.value
                : numericValue.toLocaleString("ru")}
            </div>
          </div>
        );
      },
      hideable: dateColumnIndices.includes(i),
      cellClassName: (params) => {
        const { headerName, field } = params.colDef;
        const { row, value } = params;
        const fieldNumericIndex = Number(field);
        const numericValue = Number(value);
        if (
          isDateHeader(headerName) &&
          fieldNumericIndex < categoryColumns.length - 3
        ) {
          const topValue = Number(row[categoryColumns.length - 3]);
          const prevDayValue = Number(row[fieldNumericIndex + 1]);
          if (Number.isNaN(numericValue)) return "positionOther";
          if (numericValue === topValue) return "positionMatch";
          if (numericValue > prevDayValue) return "positionLower";
          if (numericValue < prevDayValue) return "positionHigher";
          return "positionOther";
        }
        if (headerName === "Топ") return "top-header";
        return "";
      },
      sortComparator: (v1, v2) => {
        const numV1 = Number(getNumericalValueForSorting(v1)) || 0;
        const numV2 = Number(getNumericalValueForSorting(v2)) || 0;
        return Number(numV1) - Number(numV2);
      },
      sortingOrder: [
        1,
        categoryColumns.length - 1,
        categoryColumns.length - 2,
      ].includes(i)
        ? ["asc", "desc"]
        : ["desc", "asc"],
    });
  }

  return { rows, columns };
};

export const getTablesData = (data) => {
  const tableColumns = [...data.tables_cols];

  const dateColumnIndices = tableColumns
    .map((_, index) => index)
    .filter((index) => isDateHeader(tableColumns[index]));
  tableColumns.push("Топ", "Дельта", "Дельта ТОПа");
  const tables = {};
  const tabs = data.tabs;

  // Loading "all" table

  const allTableRows = {}; // cache already calculated data

  tables["all"] = {};
  const currentTable = data.tables_data["all"];
  tables["all"]["all"] = [];
  const usedQueries = new Set();
  for (const section in currentTable) {
    tables["all"][section] = [];
    for (const query in currentTable[section]) {
      const row = { id: query };
      let dateValues = [];
      for (let i = 0; i < tableColumns.length - 3; ++i) {
        const rawValue = dateColumnIndices.includes(i)
          ? currentTable[section][query][i]
          : currentTable[section][query][i] || 0;
        const currentCellValue = dateColumnIndices.includes(i)
          ? getFormattedValue(rawValue)
          : rawValue;
        row[i] = currentCellValue;
        if (dateColumnIndices.includes(i)) {
          dateValues.push(currentCellValue);
        }
      }
      const filteredDateValue = dateValues.filter(
        (val) => !Number.isNaN(Number(val))
      );
      const topDateValue =
        filteredDateValue.length > 0 ? Math.min(...filteredDateValue) : "−";
      row[tableColumns.length - 3] = topDateValue;
      row[tableColumns.length - 2] = !Number.isNaN(
        dateValues[1] - dateValues[0]
      )
        ? dateValues[1] - dateValues[0]
        : null;
      row[tableColumns.length - 1] = !Number.isNaN(topDateValue - dateValues[0])
        ? topDateValue - dateValues[0]
        : null;
      tables["all"][section].push(row);
      allTableRows[query] = row;
      if (!usedQueries.has(query)) {
        tables["all"]["all"].push(row);
        usedQueries.add(query);
      }
    }
  }

  for (const key in data.tables_data) {
    if (key === "all") continue;
    tables[key] = {};
    const currentTable = data.tables_data[key];
    tables[key]["all"] = [];
    const usedQueries = new Set();
    for (const section in currentTable) {
      tables[key][section] = [];
      for (const query in currentTable[section]) {
        const row = allTableRows[query];
        tables[key][section].push(row);
        if (!usedQueries.has(query)) {
          tables[key]["all"].push(row);
          usedQueries.add(query);
        }
      }
    }
  }

  const columns = [
    {
      field: "0",
      headerName: tableColumns[0],
      width: 400,
      hideable: true,
      headerAlign: "left",
      headerClassName: "pinned-header",
      renderHeader: (params) => {
        return (
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{
              borderRight: "",
              display: "flex",
              justifyContent: "space-between",
              width: "330px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontWeight: 500,
              }}
            >
              {"/// Запросы"}
            </Box>
            <Box>
              <GridToolbarFilterButton />
            </Box>
          </Box>
        );
      },
      renderCell: (params) => <RenderedCell params={params} />,
    },
  ];
  for (let i = 1; i < tableColumns.length; ++i) {
    const isDateColumn = dateColumnIndices.includes(i);
    columns.push({
      field: i.toString(),
      headerName: tableColumns[i],
      width: isDateColumn ? 70 : 110,
      headerClassName: `header-flex-reverse header ${
        isDateColumn ? "" : "pinned-header"
      }`,
      headerAlign: "left",
      type: "number",
      getApplyQuickFilterFn: undefined,
      renderCell: (params) => {
        const numericValue = Number(params.value);
        return (
          <div>
            <div>
              {Number.isNaN(numericValue)
                ? params.value
                : numericValue.toLocaleString("ru")}
            </div>
          </div>
        );
      },
      hideable: !dateColumnIndices.includes(i),
      cellClassName: (params) => {
        const { headerName, field } = params.colDef;
        const { row, value } = params;
        const fieldNumericIndex = Number(field);
        const numericValue = Number(value);
        if (
          isDateHeader(headerName) &&
          fieldNumericIndex < tableColumns.length - 3
        ) {
          const topValue = Number(row[tableColumns.length - 3]);
          const prevDayValue = Number(row[fieldNumericIndex + 1]);
          if (/\d[kKкК]\+/g.test(value)) return "position-k-plus";
          if (Number.isNaN(numericValue)) return "positionOther";
          if (numericValue === topValue) return "positionMatch";
          if (numericValue > prevDayValue) return "positionLower";
          if (numericValue < prevDayValue) return "positionHigher";
          return "positionOther";
        }
        if (headerName === "Топ") return "top-header";
        return "";
      },
      sortComparator: (v1, v2) => {
        const numV1 = Number(getNumericalValueForSorting(v1)) || 0;
        const numV2 = Number(getNumericalValueForSorting(v2)) || 0;
        return Number(numV1) - Number(numV2);
      },
      sortingOrder: [
        1,
        tableColumns.length - 1,
        tableColumns.length - 2,
      ].includes(i)
        ? ["asc", "desc"]
        : ["desc", "asc"],
    });
  }
  return { tables, columns, tabs };
};
